import { Fragment } from 'react';

import { Outlet, Link } from 'react-router-dom';

import Footer from './Footer/Footer';

import logo from './MOLWEIN_LOGO_R.jpg';
import './navigation.scss';

const Navigation = () => {

  const handleMbMenu = () => {
    const mbMenu = document.getElementById('mb-menu-opener');
    const hdrNavMenu = document.getElementById('header-nav-menu');
    
    if(mbMenu.classList.contains('open')) {
      mbMenu.className = '';
      hdrNavMenu.classList.remove('mobile-open');
    } else {
      mbMenu.className = 'open';
      hdrNavMenu.classList.add('mobile-open');
    }
  };

  const handleNavMenuClick = () => {
    const mbMenu = document.getElementById('mb-menu-opener');
    const hdrNavMenu = document.getElementById('header-nav-menu');
    hdrNavMenu.classList.remove('mobile-open');
    mbMenu.classList.remove('open');
  };

  return(
    <Fragment>
      <header>
        <Link className='logo-container' to='/'>
          <img alt='molwein' src={logo} />
        </Link>
        <div id='header-nav-menu' className='nav-container'>
          <Link className='nav-link' onClick={handleNavMenuClick} to='/'>APPS</Link>
          <Link className='nav-link' onClick={handleNavMenuClick} to='/how-to'>HOW TO</Link>
          <Link className='nav-link' onClick={handleNavMenuClick} to='/contact'>CONTACT US</Link>
        </div>
        <div id="mb-menu-opener" onClick={handleMbMenu}>
          <div className='mblp-f'></div>
          <div className='mblp-s'></div>
          <div className='mblp-t'></div>
        </div>
      </header>
      <section className='main'>
        <Outlet />
      </section>
      
      <Footer />
      
    </Fragment>
  );
};

export default Navigation;