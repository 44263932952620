import backgroundv from "../../img/video-icon.png";
import backgroundc from "../../img/contact-icon.png";
import './HomePage.scss';

const HomePage = () => {
  return(
    <div className="homepage">
      <h1 style={{marginTop: '60px'}}>COMING SOON ...</h1>
      <div style={{marginTop: '60px'}}></div>
      <p style={{marginBottom: '60px'}}>please visit:</p>
      <a className="video-icon" style={{backgroundImage: `url(${backgroundv})`}} href="/how-to"><span className='linkTxt'>How to Videos</span></a>
      <a className="video-icon" style={{backgroundImage: `url(${backgroundc})`}} href="/contact"><span className='linkTxt'>Contact Us</span></a>
    </div>
  );
};

export default HomePage;