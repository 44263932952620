

import './PrivacyPage.scss';

const PrivacyPage = () => {

  return (
    <section class='page-content privacy-page'>
      <h1>Privacy Policy</h1>
      <p>Date: 05 June 2023</p>
      <h2>1.	Who should read this Privacy Policy?</h2>
      <p>If you visit and interact with our website and/or use any of the services we provide, we collect and process your personal data.</p>
      <p>This Privacy Policy explains what types of personal data we collect about you, how we use your data, and what legal bases we rely on to collect and process your personal data. </p>
      <h2>2.	Who is the Data Controller</h2>
      <p>Business Name: MOLWEIN</p>
      <p>Business address: 1 Kavanagh Court, Lime Grove, New Malden, London, KT3 3TJ, United Kingdom</p>
      <h2>3.	What personal data do we collect about you? How do we collect your personal data?</h2>
      <p>We collect and process various categories of personal data about you such as your email address, your activity concerning the use of our services, and how you browse our website.</p>
      <p>The table below explains what categories of data we collect about you and how.</p>
      <table>
        <thead>
          <tr>
            <th>Types of data collected about you</th>
            <th>How we collect your data</th>
          </tr>
        </thead>
        <tbody>
        <tr>
          <td>Basic details such as your business name, your personal/business email address, Shopify shop ID</td>
          <td>You voluntarily provide these data when you register to use our Shopify App service</td>
        </tr>
        <tr>
          <td>We keep records of your activities when you use our Shopify app on your Shopify platform</td>
          <td>We automatically collect this data</td>
        </tr>
        <tr>
          <td>How you browse our website, which pages you click on, the country from which you visit our website, your device type, your Ip address</td>
          <td>We use google analytics to analyze your behavior on our website</td>
        </tr>
        <tr>
          <td>
            <p>HTTP Headers</p><p>IP addresses</p>
            <p>Information about the web browser, page location, document, referrer, and the person using the website.</p>
            <p>Buttons clicked by site visitors, the labels of those buttons, and any pages visited as a result of the button clicks.</p>
          </td>
          <td>When we use Meta Pixel on our website</td>
        </tr>
      </tbody>
      </table>

      <h2>4.	For what purposes do we use your personal data?</h2>
      <p>We collect and use your personal data to achieve the following purposes:</p>
      <ul>
        <li>To provide you with our services</li>
        <li>To analyze our website traffic and to improve our content and website</li>
        <li>To send you promotional emails</li>
        <li>To retarget you with ads</li>
      </ul>

      <h2>5.	What legal bases do we rely on to process your personal data?</h2>
      
      <table>
        <thead>
          <tr>
            <th>Processing activity</th>
            <th>Legal basis we rely on</th>
          </tr>
        </thead>
        <tbody>
        <tr>
          <td>Collection of your basic details such as your Shopify shop ID, business name and business email address</td>
          <td>We rely on “contractual necessity” ground to collect and use this data</td>
        </tr>
        <tr>
          <td>Sending promotional emails to your business email address </td>
          <td>We rely on “legitimate interests” to send marketing emails to business email addresses.</td>
        </tr>
        <tr>
          <td>
            <p>When we collect data about how you use and browse our website through the Google analytics</p>
            <p>When we use Meta Pixel to measure and track conversion events </p>
            <p>Ad retargeting</p>
          </td>
          <td>We rely on your “Consent”</td>
        </tr>
        </tbody>
      </table>

      <h2>6.	International data transfers</h2>
      <p>When we use the services of third-party service providers located in third countries, we may transfer your personal data to these third-party service providers.</p>
      <p>When we transfer your data overseas, we comply with article 46 of the UK and the EU GDPR and put in place appropriate safeguards to ensure the security of your data.</p>

      <p>What safeguards do we rely on when we transfer your data out of the EU?</p>
      <p>When we transfer personal data subject to EU GDPR to a non-adequate third-country such as the USA, we rely on the Standard Contractual Clauses approved by the EU Commission, which can be found at:</p>
      <p><a target="_blank" href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj" rel="noreferrer">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj</a></p>
      <p><a target="_blank" href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A32010D0087" rel="noreferrer">https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A32010D0087</a></p>
      <p><a target="_blank" href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A32004D0915" rel="noreferrer">https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A32004D0915</a></p>

      <h2>What safeguards do we rely on when we transfer your data out of the UK?</h2>
      <p>When we transfer personal data out of the UK to a non-adequate third-country such as the USA, we rely on UK International Data Transfer Agreement(IDTA), which is approved by the UK Data Protection Authority(ICO). The UK IDTA can be found <u><a target="_blank" href="https://ico.org.uk/for-organisations/uk-gdpr-guidance-and-resources/international-transfers/international-data-transfer-agreement-and-guidance/" rel="noreferrer">here</a></u></p>
      <p>When we enter into a data processing agreement with our service providers, we may implement the UK IDTA by means of the “UK Addendum”, annexed to the EU SCCs as approved by the ICO.</p>

      <h2>7.	Data security </h2>
      <p>To ensure and maintain the security of your personal data, we implement state-of-the-art appropriate security measures. To learn more about the measures we apply, you can contact us as described in section 12 of this Privacy Policy.</p>

      <h2>8.	What are your rights in relation to your personal data?</h2>
      <p>If the EU or the UK GDPR applies to the processing of your personal data, you can exercise the following rights:</p>
      <h3>Right of Access:</h3>
      <p>Right of access allows you to submit a request to confirm if we hold your data and to obtain copies of the personal data we have concerning you. However, this right is not absolute and it is subject to certain limitations as specified under applicable laws.</p>
      <h3>Right to Rectify your personal data:</h3>
      <p>You may ask for the correction of any inaccuracies in relation to your personal data.</p>
      <h3>Right to deletion:</h3>
      <p>You can ask for the deletion of personal data held about you. However, this right is not absolute and it is subject to limitations specified in the applicable laws.</p>
      <h3>Right to Restrict the processing of your personal data:</h3>
      <p>You may submit a request for the restriction of processing of your personal data on the basis that the data concerning you is not accurate or the processing of your data is not legitimate.</p>
      <h3>Right to Object to the processing of your personal data:</h3>
      <p>If we are relying on legitimate interests to process your personal data, you may object to such processing activities.</p>
      <h3>Right to Data Portability:</h3>
      <p>To the extent it is applicable, you can request that we transfer all personal data held about you to another organization.</p>

      <h2>9.	How can you exercise your rights?</h2>
      <p>If you wish to exercise any of the rights listed in section 9 of this Privacy Policy, you can submit a request in one of the following ways:</p>
      <h3>Email:</h3>
      <p>You can send an email to us at: <span class="mail">support@molwein.com</span></p>
      <h3>Post</h3>
      <p>Alternatively, you can also submit a request to the postal address below:</p>
      <p>1 Kavanagh Court, Lime Grove, New Malden, London, KT3 3TJ, United Kingdom</p>

      <h2>10.	How to file a complaint with Supervisory Authorities</h2>
      <p>If you are in the EU or the UK, you may file a complaint with the competent Data Protection Authority within that country.</p>
      <p>For example, if processing of your personal data falls under the scope of the UK GDPR, you can get in touch with the UK Data Protection Authority (ICO) via the following ways:</p>
      <p>The ICO’s postal address:</p>
      <p>Information Commissioner’s Office</p>
      <p>Wycliffe House</p>
      <p>Water Lane</p>
      <p>Wilmslow</p>
      <p>Cheshire</p>
      <p>SK9 5AF</p>
      <p>United Kingdom</p>

      <p>ICO Helpline number:</p>
      <p>+44 (0) 303 123 1113</p>

      <p>ICO website:</p>
      <p><a target="_blank" href="https://www.ico.org.uk" rel="noreferrer">https://www.ico.org.uk</a></p>

      <p>For country-specific information about how you can submit a formal complaint, you may refer to the following directory to contact the relevant supervisory authority:</p>
      <p><a target="_blank" href="https://iapp.org/resources/global-privacy-directory/" rel="noreferrer">https://iapp.org/resources/global-privacy-directory/</a></p>

      <h2>11.	How to contact us</h2>
      <p>For any queries concerning the processing of your personal data, you can contact us at:</p>
      <p>Email: <span class="mail">support@molwein.com</span></p>
      <p>Physical address: 1 Kavanagh Court, Lime Grove, New Malden, London, KT3 3TJ, United Kingdom</p>
    </section>
  );
};

export default PrivacyPage;
