
import { Link } from 'react-router-dom';

import './Footer.scss';
const Footer = () => {

  return (
    <footer>
      <div className='footer-content'>
        <Link className='footer-link' to='/privacy-policy'>Privacy Policy</Link>
        <Link className='footer-link' to='/terms-conditions'>Terms & Conditions</Link>
        <Link className='footer-link' to='/cookies-policy'>Cookies Policy</Link>
      </div>
      <div className='footer-content'>
        <span className='cpyrttxt'>&copy; Copyright 2023 MOLWEIN.COM</span>
      </div>
    </footer>
  );
};

export default Footer;
