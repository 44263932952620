import { Routes, Route } from 'react-router-dom';

import Navigation from './routes/Navigation/Navigation';
import HomePage from "./routes/HomePage/HomePage";
import HowtoPage from './routes/HowtoPage/HowtoPage';
import ContactPage from './routes/ContactPage/ContactPage';
import PrivacyPage from './routes/PrivacyPage/PrivacyPage';
import TermsPage from './routes/TermsPage/TermsPage';
import CookiesPage from './routes/CookiesPage/CookiesPage';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Navigation />}>
        <Route index element={<HomePage />} />
        <Route path='how-to' element={<HowtoPage />} />
        <Route path='contact' element={<ContactPage />} />
        <Route path='privacy-policy' element={<PrivacyPage />} />
        <Route path='terms-conditions' element={<TermsPage />} />
        <Route path='cookies-policy' element={<CookiesPage />} />
      </Route>
    </Routes>
  );
}

export default App;
