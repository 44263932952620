import { useState } from 'react';

import './ContactPage.scss';

const validEmailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

const ContactPage = () => {

  const [fName, setFName] = useState('');
  const [fEmail, setFEmail] = useState('');
  const [fMessage, setFMessage] = useState('');

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const errorContainer = document.getElementById('cform-err-container');
    errorContainer.textContent = '';

    let errMsg, errName, errEmail;
    let errorArr = [];
    if(fName.toString().trim() === '') {
      errName = 'Please enter your name';
      errorArr.push(errName);
    }
    if(fEmail.toString().trim() === '') {
      errEmail = 'Please enter your email';
      errorArr.push(errEmail);
    } else {
      if (!(fEmail.toString().trim().match(validEmailRegex))) {
        errEmail = 'Please enter a valid email address';
        errorArr.push(errEmail);
      }
    }
    if(fMessage.toString().trim() === '') {
      errMsg = 'Please enter your message.';
      errorArr.push(errMsg);
    }

    if(errorArr.length !== 0) {
      for(let er = 0; er < errorArr.length; er++) {
        let errP = document.createElement('p');
        errP.innerText = errorArr[er];
        errP.className = 'fsuberr';
        errorContainer.appendChild(errP);
      }
    } else {
      
    }
  }

  const handleNameChange = (e) => {
    setFName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setFEmail(e.target.value);
  };

  const handleFMessageChange = (e) => {
    setFMessage(e.target.value);
  };

  return (
      
      <div id='page-container-contact'>
        
      <h1 className='page-title'>Contact Us</h1>

      <div className='page-exp'>
        <p>Please feel free to reach us by filling out the form below or you can send an email to support@molwein.com</p>
      </div>
        
        
        <div className='contact-form'>
          <form id='contact-page-form' onSubmit={handleFormSubmit}>
            <div className='cf-row'>
              <label className='cf-label'>Name:</label>
              <input className='cf-text' type='text' value={fName} onChange={handleNameChange}/>
            </div>
            <div className='cf-row'>
              <label className='cf-label'>Email:</label>
              <input className='cf-text' type='text' value={fEmail} onChange={handleEmailChange}/>
            </div>
            <div className='cf-row'>
              <label className='cf-label'>Message:</label>
              <textarea className='cf-mtext' value={fMessage} onChange={handleFMessageChange}></textarea>
            </div>

            <div className='cf-row'>
              <input className='cf-submit' type='submit' />
            </div>

            <div className='cf-row' id='cform-err-container'>
              
            </div>

          </form>
        </div>
      </div>
  );
}

export default ContactPage;
