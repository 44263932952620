
import './HowtoPage.scss';

const HowtoPage = () => {

  return (
    <div id='page-container-howto'>
      <p>How to Page</p>
    </div>
  );
};

export default HowtoPage;
