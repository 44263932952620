

import './CookiesPage.scss';
const CookiesPage = () => {
  return(
    <section class='page-content privacy-page'>
      <h1>Cookies Policy</h1>
      <p>Date: 16 May 2023</p>
      <p>We use cookies and other tracking technologies on our website to improve user experience, optimize our website, analyze website visitor behavior, ad retargeting, and measurement purposes.</p>
      <p>This Cookies Policy is integral to our Privacy Policy and should be construed accordingly.</p>
      <a href="https://molwein.com/privacy-policy">Link to our Privacy Policy</a>

      <h2>1. What are Cookies?</h2>
      <p>A cookie refers to a text file that is deposited onto your computer or electronic device when you visit our website. Similar technologies include social media plugins, analytics tools, web beacons, action tags, and flash cookies. We use cookies and/or similar technologies on our website.</p>

      <h2>2. What information do we collect through the use of cookies?</h2>
      <p>When we use cookies on our website, we collect the following categories of personal data:</p>
      <ul>
        <li>Your IP address</li>
        <li>Your browser and/or other internet log information</li>
        <li>HTTP Headers</li>
        <li>Information about your web browser</li>
        <li>Page location</li>
        <li>Buttons you click on our website and the labels of those buttons</li>
        <li>Pages you visit after clicking these buttons</li>
        <li>Conversion events</li>
        <li>Date and time you visited our website</li>
        <li>Geographical location from which you access our website</li>
        <li>The domain name you requested</li>
      </ul>

      <h2>3. Why do we use cookies on our Website?</h2>
      <ul>
        <li>To ensure that our website, its functionalities, and its content function properly</li>
        <li>To analyze how you visit and browse through our website so that we can improve our website and its functionalities</li>
        <li>For ad retargeting purposes</li>
        <li>To analyze website visitor profiles</li>
        <li>To ensure the security of our website</li>
      </ul>

      <h2>4. The legal basis we rely on to use cookies</h2>
      <p>Cookies can be classified into “Functional cookies”, “Analytics cookies” and “Performance/tracking cookies”.</p>
      <p>We rely on different legal bases under the EU and the UK GDPR to use these cookies:</p>

      <table>
        <thead>
          <tr>
            <th>Type of Cookies</th>
            <th>Legal basis</th>
          </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            <h3>Functional cookies:</h3>
            <p>These refer to cookies that are “essential” for the functionality of our website.</p>
          </td>
          <td>We rely on “Legitimate interests” to place these cookies.</td>
        </tr>
        <tr>
          <td>
            <h3>Analytics & Tracking Cookies:</h3>
            <p>These cookies refer to cookies that enable us to analyze our website visitor behavior, track visitors across different websites, and retarget them with ads.</p>
            <p>For instance, “google analytics” is an example of analytics cookies.</p>
            <p>For instance, “Meta pixel” is an example of tracking cookies.</p>
          </td>
          <td>We obtain consent before activating these cookies on our website.</td>
        </tr>
      </tbody>
      </table>

      <h2>5.	Important notice about Google Analytics</h2>
      <p>Google Analytics cookie enables us to analyze website traffic and better understand visitor behavior on our website.</p>
      <p>When we use google analytics, we collect personal data such as:</p>
      <ul>
        <li>Information about the geographical area from which you visit our website</li>
        <li>Information about how you surf through our Website</li>
        <li>Previous pages you visited (if you came to our website by following a link)</li>
        <li>The timestamp of your relevant visit such as the specific time and date</li>
        <li>Your browser settings</li>
        <li>Information concerning your device model</li>
        <li>For more detailed information, please refer to Google’s Privacy Policy:
          <p><a href="https://policies.google.com/privacy?hl=en-US">https://policies.google.com/privacy?hl=en-US</a></p>
        </li>
      </ul>

      <h2>6. Important notice about Meta Pixel</h2>
      <p>Meta Pixel is a javascript code placed on our website to collect data about how our website visitors browse through our website. For instance, it collects information about what pages a website visitor clicks on and what conversion events occur.</p>
      <p>Information captured by Meta Pixel includes, but is not limited to:</p>
      <ul>
        <li>Your IP address</li>
        <li>HTTP Headers</li>
        <li>Buttons you click on our website and the labels of those buttons</li>
        <li>Pages you visit after clicking these buttons</li>
        <li>Conversion events (As defined by us)</li>
      </ul>
      <p>To learn more about how Meta Pixel works and what data it collects, visit this page:</p>
      <p>< a href="https://developers.facebook.com/docs/meta-pixel/">https://developers.facebook.com/docs/meta-pixel/</a></p>
      <p>To learn more about how Meta may collect and use your personal data as a data controller through Meta Pixel, refer to Meta Privacy Policy:</p>
      <p><a href="https://www.facebook.com/privacy/policy/">https://www.facebook.com/privacy/policy/</a></p>

      <h2>7. How to disable cookies</h2>
      <p>You can disable cookies in two ways:</p>
      <ul>
        <li>
            <p><b>By rejecting cookies on our website through the interactive cookie banner</b></p>
            <p>You can reject analytics and performance cookies by clicking the “reject” button on the cookie banner.</p>
        </li>
        <li>
            <p><b>By browser settings</b></p>
            <p>Depending on the type of browser you use, you can visit the following pages to disable Cookies:</p>
        </li>
      </ul>
      <p><a href="https://support.google.com/chrome/answer/95647?hl=en">https://support.google.com/chrome/answer/95647?hl=en</a></p>
      <p><a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&redirectslug=Cookies">https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&redirectslug=Cookies</a></p>
      <p><a href="https://support.microsoft.com/en-us/topic/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d">https://support.microsoft.com/en-us/topic/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d</a></p>
      <p><a href="https://support.apple.com/en-us/HT201265">https://support.apple.com/en-us/HT201265</a></p>
      
      <h2>8.	For how long are cookies stored?</h2>
      <p>When we place cookies that are not categorized as “strictly necessary”, they are stored on your device for a maximum period of six (6) months.</p>
      
    </section>
  );
};

export default CookiesPage;
