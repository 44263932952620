
import './TermsPage.scss';
const TermsPage = () => {
  return(
    <section class='page-content privacy-page'>
      <h1>Terms & Conditions</h1>
      <p>Effective date: 08 June 2023</p>

      <p>By accessing our website and by our website and Shopify app, you agree to be bound by these Terms.</p>

      <h2>1. DEFINITIONS</h2>
      <p>In the context of these Terms, “Company” refers to “MOLWEIN” with the business address at 1 Kavanagh Court, Lime Grove, New Malden, London, KT3 3TJ, United Kingdom.</p>
      <p>In the context of these Terms, “You/User/Visitor” means our website visitors and customers who use our services including our Shopify app.</p>
      <p>“App” means any e-commerce Applications provided by the Company.</p>
      <p>“Website” refers to the site at: https://molwein.com</p>

      <h2>2. INTELLECTUAL PROPERTY RIGHTS</h2>
      <p>The content on our Website, its functionalities, and our website and our app as a whole are and remain the property of us.</p>
      <p>By visiting our website, and using our app or services, you agree that:</p>
      <ul>
        <li>You shall not reverse engineer or make any attempt to extract the source code of our website or our app;</li>
        <li>You shall not  copy, modify, license, sub-license, modify, distribute, resell, transfer, or lease any part of our website, the content therein, or our app;</li>
        <li>You shall not launch or use scripts or programs on our website, our app, or their content for scraping indexing, or surveying purposes purposes;</li>
        <li>You shall not create or register (i) businesses, (ii) URLs, c)software application names/domain names, (iii)social media profiles or pages that contain our Company names, our trading names, our trademarks, or other names or symbols that are similar to our Company names, logos or trademarks in a substantial way;</li>
        <li>You shall not use Company Names, Marks, or Works as your social media profile picture or wallpaper;</li>
        <li>You shall not purchase keywords (including, but not limited to Google AdWords) that contain any (a) Company Names, (b)Marks, c) Works.</li>
      </ul>

      <h2>3. PROHIBITED ACTIVITIES</h2>
      <p>The user agrees that he/she will not get involved in any of the prohibited activities as described below:</p>
      <ul>
        <li>Use of our website, its functionalities, or its content for any unlawful purposes;</li>
        <li>To solicit third parties to carry out unlawful acts;</li>
        <li>To violate intellectual property rights, including our IP rights over our website;</li>
        <li>To provide misleading or inaccurate information;</li>
        <li>To circumvent the security measures we implement on our website and platform;</li>
        <li>To reverse engineer, decompile, or reduce to human-perceivable form all or any part of our services in any manner.</li>
      </ul>

      <h2>4. WARRANTIES</h2>
      <p>Both the Company and the User hereby warrant that:</p>
      <ul>
        <li>It has full power and is legally authorized to be bound by these Terms;</li>
        <li>It will fulfil its obligations specified under these Terms in compliance with all applicable laws and regulations and by exercising reasonable skill and care;</li>
        <li>It will not make any false, misleading, or disparaging representations or statements regarding the other party;</li>
        <li>It shall comply with all relevant tax laws.</li>
      </ul>

      <h2>5. INDEMNIFICATION</h2>
      <p>The User hereby agrees that it shall indemnify, defend and hold harmless the Company (including its directors, contractors, and employees), from and against any claims, costs, damages, losses, liabilities, and expenses (including legal fees) relating to any claims, actions, suits or proceedings by third parties against Company arising out of or related in any way to any breach by the User of any of the warranties.</p>
      
      <h2>6. DISCLAIMER OF WARRANTIES FOR RESULTS</h2>
      <p>While the Company makes its best efforts to carry out its obligations and to provide its services with utmost care and skill, the results obtained for each User may vary greatly due to a variety of factors such as the market conditions, the quality of our user’s products and services and its previous experience.</p>
      <p>By accessing and by using our website and our app and services, the User acknowledges that the Company does not guarantee or promise any particular result, outcome, or achievement.</p>
      <p>The User frees the Company of any liability for any failure and/or for failing to achieve any result that they hoped to obtain by accessing and using our app and services.</p>

      <h2>7. DISCLAIMER OF LIABILITY</h2>
      <p>The Company hereby disclaims all liability for the following, to the extent it is not prohibited under the applicable law(s):</p>
      <ul>
        <li>Loss of profits; </li>
        <li>Loss of agreements or contracts;</li>
        <li>Indirect or consequential loss;</li>
        <li>Loss of anticipated savings;</li>
        <li>Loss of sales or business;</li>
        <li>Loss of use or corruption of software, data, or information;</li>
        <li>Loss of or damage to goodwill.</li>
      </ul>

      <h2>8. LIMITATION OF LIABILITY</h2>
      <p>Unless specified to the contrary in these Terms, all warranties, conditions, and other terms implied by statute or common law are excluded to the fullest extent permitted by law.</p>
      <p>The total liability of the Company in contract, tort (including negligence or breach of statutory duty), misrepresentation, restitution, or otherwise arising in connection with the provision of Services and access to and use of the Website, its app, and services shall not exceed 500$(Five-hundred-dollars)</p>
      <p>Company hereby disclaims all express or implied warranties, including warranties of satisfactory quality and fitness for a particular purpose in relation to its website, its app, and its services and the results of the access to and use of the Services.</p>
      
      <h2>9. VIOLATIONS OF THESE TERMS AND CONDITIONS</h2>
      <p>If the Company determines that you violated any provision of these Terms and Conditions, it may, at its sole discretion, resort to the following remedies:</p>
      <ul>
        <li>Immediately terminating your access to and use of the website, the app, and its services;</li>
        <li>Bringing legal claims against you due to such a violation.</li>
      </ul>

      <h2>10.	CHANGES TO THESE TERMS AND CONDITIONS</h2>
      <p>The Company, at its sole discretion, may make changes to these Terms and Conditions at any time and such changes shall come into force immediately.</p>

      <h2>11. GOVERNING LAW</h2>
      <p>All matters relating to this Agreement shall be governed by the Laws of England and Wales. Courts of England and Wales shall have exclusive jurisdiction to resolve any disputes in relation to or arising out of this Agreement.</p>

    </section>
  );
};

export default TermsPage;
